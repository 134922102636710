import {
    SET_SLIDE
} from '../types';

export default (state, action) => {
    switch (action.type) {
        case SET_SLIDE:

            let slide, contentId;
            [slide, contentId] = action.payload;
            let currentSlides = { ...state.currentSlides };
            currentSlides[contentId] = slide;

            return {
                ...state,
                currentSlides: currentSlides
            };
        default:
            return state;
    }
};