import React, { useReducer } from 'react';
import GalleryContext from './galleryContext';
import GalleryReducer from './galleryReducer';

import {
    SET_SLIDE
} from '../types';

const GalleryState = props => {
    const initialState = {
        currentSlides: {}
    }

    const [state, dispatch] = useReducer(GalleryReducer, initialState);

    // set slide
    const setCurrentSlide = (slide, contentId) => {
        dispatch({
            type: SET_SLIDE,
            payload: [slide, contentId]
        });
    };

    // set slide
    const getCurrentSlide = contentId => {
        return state.currentSlides[contentId] ? state.currentSlides[contentId] : 0;
    };


    return <GalleryContext.Provider
        value={{
            currentSlides: state.currentSlides,
            setCurrentSlide: setCurrentSlide,
            getCurrentSlide: getCurrentSlide
        }}>
        {props.children}
    </GalleryContext.Provider>
};

export default GalleryState;