import React from 'react';

const Textnl2p = ({ text }) => {

    return (
        <React.Fragment>
            {text.split('\n').map((item, key) => {
                return <p key={key}>{item}</p>
            })}
        </React.Fragment>
        
    )
};
export default Textnl2p;
